<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-end space-x-2">
      <router-link tag="div" to="/customer/add">
        <l-button class="bg-brand rounded text-white" :text="$t('addBalance')">
          <template v-slot:icon>
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          </template>
        </l-button>
      </router-link>
    </div>
    <div class="flex py-4">
      <l-table>
        <template #thead>
          <l-t-head>
            <l-tr>
              <l-th> {{ $t("id") }} </l-th>
              <l-th> {{ $t("customer") }} </l-th>
              <l-th> {{ $t("emailId") }} </l-th>
              <l-th> {{ $t("street") }} </l-th>
              <l-th> {{ $t("city") }} / {{ $t("postal") }} </l-th>
              <l-th> {{ $t("amount") }} </l-th>
              <l-th> {{ $t("balance") }} </l-th>
            </l-tr>
          </l-t-head>
        </template>
        <template #tbody>
          <l-t-body>
            <l-tr :key="index" v-for="(item, index) in walletHistory">
              <l-td>
                <p>{{ item.customer_id }}</p>
              </l-td>
              <l-td>
                <p class="">
                  {{ item.customer }}
                </p>
              </l-td>
              <l-td>
                <p>{{ item.email }}</p>
              </l-td>
              <l-td>
                <p>{{ item.street }}</p>
              </l-td>
              <l-td>
                <div class="text-center block w-[fit-content]">
                  <p>{{ item.city }}</p>
                  <p class="opacity-80 text-gray-600 text-sm">
                    {{ item.postal_code }}
                  </p>
                </div>
              </l-td>
              <l-td>
                <p class="text-brand font-medium">
                  {{ $filters.euroFormat(item.amount) }}
                </p>
              </l-td>
              <l-td>
                <p class="font-medium">
                  {{ $filters.euroFormat(item.balance) }}
                </p>
              </l-td>
            </l-tr>
          </l-t-body>
        </template>
      </l-table>
    </div>
    <div class="items-center flex justify-center" v-if="totalPages > 0">
      <l-pagination
        :max="totalPages"
        :value="activatePage"
        @input="pageUpdated"
      ></l-pagination>
    </div>
  </div>
</template>

<script>
import { PlusIcon, DownloadIcon } from "@heroicons/vue/outline";
import LButton from "@/components/custom/LButton";
import LTable from "@/components/custom/table/LTable";
import LTHead from "@/components/custom/table/LTHead";
import LTr from "@/components/custom/table/LTr";
import LTd from "@/components/custom/table/LTd";
import LTh from "@/components/custom/table/LTh";
import LTBody from "@/components/custom/table/LTBody";
import { mapGetters } from "vuex";
import LPagination from "@/components/custom/pagination/LPagination";

export default {
  name: "WalletList",
  components: {
    LPagination,
    LTable,
    LTHead,
    LButton,
    PlusIcon,
    LTr,
    LTh,
    LTd,
    LTBody,
  },
  data() {
    return {
      activeTab: 0,
      activatePage: 1,
    };
  },
  computed: {
    ...mapGetters(["walletHistory", "walletHistoryFilters"]),
    totalPages() {
      let total =
        this.walletHistoryFilters.total / this.walletHistoryFilters.limit;
      if (total) {
        return Math.ceil(total);
      } else {
        return 0;
      }
    },
  },
  methods: {
    prepareGetData() {
      let data = {
        page: this.activatePage - 1,
      };
      return data;
    },
    fetchWalletHistory() {
      this.$store.dispatch("fetchWalletHistory", this.prepareGetData());
    },
    // downloadCustomers() {
    //   const params = this.prepareGetData();
    //   params["download"] = 1;
    //   this.$store
    //     .dispatch("fetchWalletHistoryList", params)
    //     .then((data) => {
    //       this.$filters.downloadCSV(data, "customers.csv");
    //       // const hiddenElement = document.createElement("a");
    //       // hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`;
    //       // hiddenElement.target = "_blank";
    //       // hiddenElement.download = "customers.csv";
    //       // hiddenElement.click();
    //     });
    // },
    pageUpdated(value) {
      this.activatePage = value;
      this.fetchWalletHistory();
    },
  },
  mounted() {
    this.fetchWalletHistory();
  },
};
</script>

<style scoped></style>
